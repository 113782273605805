<template>
  <div class="payment-block" v-if="Object.hasOwnProperty.call(getUserSettings, 'payments')">
    <div class="payment-block__inner"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit"
              @click="editTranslate(['payments_balance', 'payments_balanceUp', 'payments_balanceCurrently', 'payments_payByCard', 'payments_braintree', 'payments_payPayPal', 'payments_sum', 'payments_bankFees', 'payments_Total', 'payments_payNow'])"></span>
      <div class="payment-block__col">
        <div class="payment-block__name">
          {{$t('payments_balance.localization_value.value')}}
        </div>
        <div class="payment-block__description">
          {{$t('payments_balanceUp.localization_value.value')}}
        </div>
      </div>
      <div class="payment-block__col">
        <div class="payment-block__currently"
             v-if="!isSubUser"
        >
          {{$t('payments_balanceCurrently.localization_value.value')}}
          <span>
            ${{getUserProfile.balance}}
          </span>
        </div>

        <div class="payment-block__radio mb-2">
          <div class="payment-block__radio-i"
               v-if="getCurrentUserSetting && getCurrentUserSetting.use_paypal">
<!--            <RadioDefault-->
<!--                    :name="'paymentblockname'"-->
<!--                    :label="$t('payments_payByCard.localization_value.value')"-->
<!--                    :value="true"-->
<!--                    @change.native="changeRadio(PAYMENT_TYPE.authorize)"-->
<!--            />-->
<!--          </div>-->
<!--          <div class="payment-block__radio-i"-->
<!--               v-if="_.has(currentPermissions, PERMISSIONS.PAYPAL_PAY_DASHBOARD)"-->
<!--          >-->

            <RadioDefault
                    :name="'paymentblockname'"
                    :label="$t('payments_payPayPal.localization_value.value')"
                    :value="payType === PAYMENT_TYPE.payPall"
                    @change.native="changeRadio(PAYMENT_TYPE.payPall)"
            />
          </div>
          <div class="payment-block__radio-i"
               v-if="_.has(currentPermissions, PERMISSIONS.CUSTOMER_PAYMENT_BRAINTREE)"
          >

            <RadioDefault
                    :name="'paymentblockname'"
                    :label="$t('payments_braintree.localization_value.value')"
                    :value="payType === PAYMENT_TYPE.braintree"
                    @change.native="changeRadio(PAYMENT_TYPE.braintree)"
            />
          </div>
<!--               v-if="_.has(currentPermissions, PERMISSIONS.CUSTOMER_PAYMENT_BRAINTREE)"-->
        </div>
        <div class="payment-block__radio">

          <div class="payment-block__radio-i">
            <RadioDefault
                :name="'paymentblockname'"
                :label="'Payoneer'"
                :value="payType === PAYMENT_TYPE.payoneer"
                @change.native="changeRadio(PAYMENT_TYPE.payoneer)"
            />
          </div>
        </div>


        <template v-if="PAYMENT_TYPE.payoneer !== payType">
          <div class="payment-block__input">
            <!--<DefaultInput-->
            <!--:label="$t('payments_sum.localization_value.value')+', $'"-->
            <!--:value="sumToPay"-->
            <!--:placeholder="'100'"-->
            <!--:type="'text'"-->
            <!--v-bind:class="{'ui-no-valid': validation.sumToPay}"-->
            <!--:error="validation.sumToPay"-->
            <!--:errorTxt="$t(`${validationTranslate.sumToPay}.localization_value.value`)"-->
            <!--@input="changeInput"-->
            <!--/>-->
            <InputSum
                :label="$t('payments_sum.localization_value.value')+', $'"
                :value="sumToPay"
                :placeholder="'100'"
                :type="'text'"
                v-bind:class="{'ui-no-valid': validation.sumToPay}"
                :error="validation.sumToPay"
                :errorTxt="$t(`${validationTranslate.sumToPay}.localization_value.value`)"
                @input="changeInput"
            />
          </div>
          <div class="payment-block__currently">
            {{$t('payments_bankFees.localization_value.value')}}
            <span>
            ${{(bankFeeds - sumToPay).toFixed(2)}}
          </span>
          </div>
        </template>

      </div>
      <div class="payment-block__total site-line-total" v-if="PAYMENT_TYPE.payoneer !== payType">
        {{$t('payments_Total.localization_value.value')}}
        <span>${{this.bankFeeds === '' ? 0 : this.bankFeeds}}</span>
      </div>

      <div v-else class="payment-block__col" style="height: 200px;"></div>

      <div class="payment-block__btn">
        <MainButton
            v-if="PAYMENT_TYPE.payoneer === payType"
            v-bind:class="{'disabled-btn' : getPayPallBtn}"
            :value="$t('dashboard_sendCheck.localization_value.value')"
            @click.native="createPayoneerCheckPopup = true"
        />
        <MainButton
            v-else
                v-bind:class="{'disabled-btn' : getPayPallBtn}"
                :value="$t('payments_payNow.localization_value.value')"
                @click.native="createInternalPayment"
        />
      </div>
    </div>

    <AuthorizePaymentPopup
            v-if="authorizePaymentPopup"
            @close="changeAuthorizePaymentPopup(false)"
            @submitForm="payAuthorize"
            :countMoney="bankFeeds"
            :authorizeError="authorizeError"
            :serverError="serverError"
    />

    <BraintreePaymentPopup
        v-if="braintreePaymentPopup"
        :amount="bankFeeds"
        @submitForm="changeThankYouPopupPopup(true)"
        @close="changeBraintreePaymentPopup(false)"
    />

    <ThankYouPopup
            v-if="thankYouPopupPopup"
            @close="changeThankYouPopupPopup(false)"
            :title="'common_paySuccessTitle'"
            :text="'common_paySuccessText'"
            :linkLastText="'common_paySuccessTextLink'"
            :linkLastTextHref="GET_PATHS.financeMyPayments + `?type=${payType.toLowerCase()}`"
    />

    <CreatePayoneerCheckPopup
        v-if="createPayoneerCheckPopup"
        @close="createPayoneerCheckPopup = false"
    />

  </div>
</template>

<script>
  // import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
  import MainButton from "../../../../UI/buttons/MainButton/MainButton";
  import RadioDefault from "../../../../UI/radiobuttons/RadioDefault/RadioDefault";
  // import {fieldNumbers} from "../../../../../services/validation";
  import AuthorizePaymentPopup from "../../../../coreComponents/Popups/AuthorizePaymentPopup/AuthorizePaymentPopup";
  import {PAYMENT_TYPE} from "../../../../../staticData/staticVariables";
  import ThankYouPopup from "../../../../coreComponents/Popups/ThankYouPopup/ThankYouPopup";
  import {paymentBlockFunc} from "../../../../../mixins/dashboardMixins/paymentBlockFunc";
  import {mapGetters} from "vuex";
  import InputSum from "../../../../UI/inputs/InputSum/InputSum";
  import BraintreePaymentPopup from "@/components/coreComponents/Popups/BraintreePaymentPopup/BraintreePaymentPopup";
  import CreatePayoneerCheckPopup
    from "@/components/coreComponents/Popups/CreatePayoneerCheckPopup/CreatePayoneerCheckPopup";

  export default {
    name: "PaymentBlock",

    components: {
      CreatePayoneerCheckPopup,
      BraintreePaymentPopup,
      InputSum,
      // DefaultInput,
      MainButton,
      RadioDefault,
      AuthorizePaymentPopup,
      ThankYouPopup,
    },

    mixins: [paymentBlockFunc],

    computed: {
      ...mapGetters([
        'getUserSettings',
        'getPayPallBtn',
        'GET_PATHS',
        'getUserProfile',
      ])
    },

    data() {
      return {
        sumToPay: '',
        authorizePaymentPopup: false,
        braintreePaymentPopup: false,
        thankYouPopupPopup: false,
        PAYMENT_TYPE: PAYMENT_TYPE,
        payType: PAYMENT_TYPE.payPall,
        bankFeeds: 0,
        serverError: false,
        authorizeError: {},
        authorizeValidation: {},

        validation:{
          sumToPay: false,
        },

        validationTranslate: {
          sumToPay: 'dashboard_EnterTheAmount',
        },

        createPayoneerCheckPopup: false,
      }
    },

    mounted() {
      if(this._.has(this.currentPermissions, this.PERMISSIONS.PAYPAL_PAY_DASHBOARD)) {
        this.changeRadio(PAYMENT_TYPE.payPall)
        return
      }

      if(this._.has(this.currentPermissions, this.PERMISSIONS.CUSTOMER_PAYMENT_BRAINTREE)) {
        this.changeRadio(PAYMENT_TYPE.braintree)
        return
      }

      this.changeRadio(PAYMENT_TYPE.payoneer)
    },

    methods: {

    },
  }
</script>

<style lang="scss">
  @import "../../../../../scss/colors";
  @import "../../../../../scss/mixins/mixins";

  .payment-block {
    background: white;
    box-shadow: 0px 4px 16px #E7DFD5;
    border-radius: 5px;
    height: 100%;

    &__inner {
      padding: 24px;
      display: flex;
      flex-wrap: wrap;
      height: 100%;

    }

    &__col {
      display: flex;
      flex-direction: column;
      width: 100%;

      @media (min-width: 550px) and (max-width: 1400px) {

        &:nth-child(1) {
          width: 60%;
        }

        &:nth-child(2) {
          align-items: flex-end;
          width: 40%;
        }
      }
    }

    &__name {
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      color: $black;
      margin-bottom: 10px;
    }

    &__description {
      font-size: 13px;
      line-height: 19px;
      color: $black;
      margin-bottom: 10px;
    }

    &__currently {
      font-size: 14px;
      line-height: 21px;
      color: $borderBrown;
      margin-bottom: 25px;

      @media (min-width: 550px) and (max-width: 1400px) {
        margin-bottom: 10px;
      }

      span {
        color: $black;
      }
    }

    &__radio {
      display: flex;
      justify-content: space-between;
      margin: 0 -6px;
      margin-bottom: 25px;

      @media (min-width: 550px) and (max-width: 1400px) {
        margin: 0;
        margin-bottom: 15px;
        flex-direction: column;
        justify-content: inherit;
      }
    }

    &__radio-i {
      padding: 0 6px;
      width: 100%;

      @media (min-width: 550px) and (max-width: 1400px) {
        padding: 0;
      }
    }

    &__input {
      max-width: 137px;
      margin-bottom: 8px;
    }

    &__total {
      width: 100%;
    }

    &__btn {
      margin-top: auto;
      width: 100%;
    }

  }

</style>
